import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.scss'],
})
export class MyprojectsComponent implements OnInit {
  IsLoading: boolean = false;
  topList: any[] = [];
  filteredListings: any[] = [];
  searchValue: string = '';
  showTopIcon = false;

  constructor(private homeService: HomeService, private router: Router) {}

  ngOnInit(): void {
    this.getProject();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100; 
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToAddProject() {
    this.router.navigate(['/add-project']);
  }

  getProject(): void {
    this.IsLoading = true;
    this.homeService.getMyProjects().subscribe(
      (res: any) => {
        this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload; 
          this.filteredListings = [...this.topList]; 
        } else {
          this.topList = [];
          this.filteredListings = [];
        }
      },
      (error) => {
        this.IsLoading = false;
        console.error('Error fetching projects:', error);
        this.topList = [];
        this.filteredListings = [];
      }
    );
  }

  // searchListings(): void {
  //   const searchTerm = this.searchValue.toLowerCase().trim();
  //   this.filteredListings = this.topList.filter((listing) =>
  //     Object.values(listing).some((value) =>
  //       value && value.toString().toLowerCase().includes(searchTerm)
  //     )
  //   );
  // }
  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();
    this.filteredListings = this.topList.filter((listing) => {
      // Normalize title by removing extra spaces and converting to lowercase
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });
  }

  clearSearch(): void {
    this.searchValue = ''; 
    this.filteredListings = [...this.topList];
  }
}
