<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>

<div class="container-fluid">
  <div class="form-container">
    <div class="form-wrapper">
      <form [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account-form">
        <a class="navbar-brand mr-0 mb-1 form" routerLink="/">
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
        </a>
        <h3 class="form-title">Reset Password</h3>

        <div class="form-group">
          <!-- <input 

            type="password" 
            placeholder="Current Password" 
            formControlName="resetpassword" 
            class="form-control"
          /> -->
          <div class="password-container">
            <input
              [type]="resetPassword ? 'text' : 'password'"
              placeholder="Current Password"
              formControlName="resetpassword"
              class="password-input form-control"
            />
            <img
              [src]="resetPassword ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
              alt="Toggle password visibility"
              class="toggle-icon "
              (click)="toggleReset()"
            />
          </div>
          <div class="error-messages" *ngIf="submitted && l.resetpassword.errors">
            <div *ngIf="l.resetpassword.errors.required">Current Password is required</div>
            <div *ngIf="l.resetpassword.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>



        <!-- <div class="password-container">
          <input
            [type]="resetPassword ? 'text' : 'password'"
            placeholder="Current Password"
            formControlName="resetpassword"
            class="password-input"
          />
          <img
            [src]="resetPassword ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
            alt="Toggle password visibility"
            class="toggle-icon"
            (click)="toggleReset()"
          />
        </div> -->
     











        <div class="form-group">
          <!-- <input 
            type="password" 
            placeholder="New Password" 
            formControlName="resetpasswordconfirm" 
            class="form-control"
          /> -->
          <div class="password-container">
            <input
              [type]="resetpasswordconfirms ? 'text' : 'password'"
              placeholder="New Password"
              formControlName="resetpasswordconfirm"
              class="password-input form-control"
            />
            <img
              [src]="resetpasswordconfirms ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
              alt="Toggle password visibility"
              class="toggle-icon "
              (click)="toggleResetconfirms()"
            />
          </div>


          <div class="error-messages" *ngIf="submitted">
            <div *ngIf="l.resetpasswordconfirm.hasError('required')">New Password is required</div>
            <div *ngIf="!l.resetpasswordconfirm.hasError('required') && l.resetpasswordconfirm.hasError('minlength')">
              Password must be at least 6 characters
            </div>
            <div *ngIf="!l.resetpasswordconfirm.hasError('required') && !l.resetpasswordconfirm.hasError('minlength') && resetform.hasError('passwordMismatch')">
              Please Provide a Different Password
            </div>
          </div>
        </div>

        <button type="submit" class="btn-reset-password">Reset Password</button>
        <a routerLink="/login" class="link-login">Login</a>
      </form>
    </div>
  </div>
</div>
</div>
<app-footer></app-footer>
</div>
