import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/_services/auth.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
@Component({
  selector: 'app-emailverify',
  templateUrl: './emailverify.component.html',
  styleUrls: ['./emailverify.component.scss']
})
export class EmailverifyComponent implements OnInit {
  otp: string = '';
  number: any;
  isMessage: boolean = false;
  message: string = '';
  status: boolean = false;
  email: string;
  @Output() emailVerified = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<EmailverifyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.email = data.email;
  }

  ngOnInit(): void {
    // Add your initialization logic here
  }

  verifyEmail(isVerified: boolean): void {
    this.emailVerified.emit(isVerified);
    this.dialogRef.close();
  }

  onInputBlur(number: string) {
    this.number = number;
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    console.log(otp);
    
  }

  verifyOtp() {
  
    console.log('OTP:', this.otp);
    console.log("Email in verify token:", this.email);

    this.authService.verifyOtp(this.email, this.otp).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          
          this.emailVerified.emit(true);
          this.dialogRef.close();
          Swal.fire({
            icon: 'success',
            title: 'User verifcation is completed.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          
        } else {
          this.message = 'Incorrect OTP. Please try again.';
          this.isMessage = true;
          Swal.fire({
            icon: 'error',
            title: 'Please Enter Valid OTP',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          setTimeout(() => {
            this.isMessage = false;
          }, 3000);
        }
      },
      (error: any) => {
        console.error('Error occurred while verifying OTP:', error);
        this.message = 'An error occurred. Please try again later.';
        this.isMessage = true;

        setTimeout(() => {
          this.isMessage = false;
        }, 3000);
      }
    );
  }
closeDialog(){
  this.dialogRef.close();
  
}
}
