<footer class="footer-classic footer">
  <div class="container">
    <div class="row">
   
      <div class="col-lg-3 col-md-6 col-sm-12 mt-3">
        <h3>Legal Content</h3>
        <ul class="nav-list">
          <li><a routerLink="/termsandcondition"><i class="fa fa-files-o" aria-hidden="true"></i> Term and Conditions</a></li><br>
          <li><a routerLink="/privacyPolicy" ><i class="fa fa-user-secret" aria-hidden="true"></i> Privacy Policy</a></li><br>
          <!-- <li><a routerLink="/FAQ" ><i class="material-icons" aria-hidden="true">question_answer</i> Faqs</a></li> -->
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-3">
        <h3>Company</h3>
        <ul class="nav-list">
          <li><a routerLink="/aboutUs"><i class="fa fa-users" aria-hidden="true"></i> About Us</a></li><br>
          <li><a routerLink="/contactUs" ><i class="fa fa-phone" aria-hidden="true"></i> Contact Us</a></li><br>
          <!-- <li><a routerLink="/news" ><i class="fa fa-newspaper-o" aria-hidden="true"></i> News</a></li> -->
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-3">
        <h3>More From Our Network</h3>
        <ul class="nav-list">
          <li><a routerLink="/news" ><i class="fa fa-newspaper-o" aria-hidden="true"></i> News</a></li><br>
          <li><a routerLink="/FAQ" ><i class="material-icons" aria-hidden="true">question_answer</i> Faqs</a></li> 
          <!-- <li><a routerLink="#"><i class="fa fa-life-ring" aria-hidden="true"></i> Career</a></li><br>
          <li><a routerLink="#" ><i class="fa fa-list" aria-hidden="true"></i> Testimonial</a></li><br>
          <li><a routerLink="#" ><i class="fa fa-book" aria-hidden="true"></i> Book Free Consultation</a></li> -->
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mt-3">
        <h3>Propease App On Mobile</h3>
        <ul class="nav-list d-flex">
          <li>
            <a href="https://play.google.com/store/apps/details?id=in.co.ksquaretech.propease&pcampaignid=web_share" target="_blank">
              <img src="../../assets/images/google-play-6647242_1280.webp" class="playlogo">
            </a>
            
          </li>
          &nbsp;&nbsp;
          <li>
            <a href="https://apps.apple.com/us/app/propease-app/id6740812565" target="_blank">
              <img src="../../assets/images/apple.png" class="applelogo">
            </a>
          </li>
          
      </ul>
      
    </div>
    
    
      <div class="col-12 mt-1 text-center">
        <ul class="nav-list">
          <li>©2024 propease.co.in Team. All Rights Reserved.</li>
        </ul>
      </div>
    </div>
  </div>
</footer>
