<!-- <mat-dialog-content>
    <div mat-dialog-title>
        <label class="mb-3 col-12 responsive-label">
            OTP has been successfully sent to your email<span class="text-danger"></span>
          </label>
    </div>
    <div mat-dialog-content class="text-center" style="border-radius: 25px;">
      <div class="spinner"></div>
      <div  id="otp"class="form-group">
        <ng-otp-input (onInputChange)="onOtpChange($event)"[config]="{length:6}"></ng-otp-input>
      </div>
      <br>
      <div class="button-container mt-2">
        <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
      </div>
    </div>
  </mat-dialog-content>
   -->
    <div class="row first-row">
      <div class="col-10">
        <span class="title">Verify OTP</span>
      </div>
      <div class="col-2 close-container">
        <button class="close-button" (click)="closeDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  
    <div class="otp-card">
      <div  id="otp"class="form-group">
        <ng-otp-input (onInputChange)="onOtpChange($event)"[config]="{length:6}"></ng-otp-input>
      </div><br>
      <div class="button-container mt-2">
        <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
      </div>
    </div>
  