<!-- <div class="slider">
  <div>
    <div (click)="goToPrevious()" class="leftArrow" style="font-size: 180%;">❰</div>
    <div (click)="goToNext()" class="rightArrow" style="font-size: 180%;">❱</div>
  </div>
  <div
    class="slide"
    [ngStyle]="{ 'background-image': getCurrentSlideUrl() }"
  ></div>
  <!-- <div class="dotsContainer">
    <div
      *ngFor="let slide of slides; let slideIndex = index"
      class="dot"
      (click)="goToSlide(slideIndex)"
    >
      ●
    </div>
  </div> -->
<!-- </div>  -->
<div class="slider">
  <div class="slide" [ngStyle]="{ 'background-image': getCurrentSlideUrl() }"></div>
  <div class="dotsContainer">
    <div
      *ngFor="let slide of slides; let slideIndex = index"
      class="dot"
      [class.active]="slideIndex === currentIndex"
      (click)="goToSlide(slideIndex)"
    >
      ●
    </div>
  </div>
</div>
