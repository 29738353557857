<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <div class="row align-items-center mt-3">
      <div class="col-12 col-md-2 mb-3 mb-md-0">
        <div class="titles">My Projects</div>
      </div>
 
      <div class="col-12 col-md-10 mb-3 mb-md-0 " >
        <!-- <mat-form-field style="width: 98%;">
          <mat-icon matPrefix class="search-icon">search</mat-icon>
          <input
          style="margin-top: 8px;"
            matInput
            placeholder="Search Your Project"
            [(ngModel)]="searchValue"
            (keyup)="searchListings()"
          />
          <button mat-icon-button matSuffix class="clear-icon" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field> -->
         
        <div class="col-12 col-md-10 mb-2 mb-md-0">
          <div class="search-container">
            <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
            <input
              type="text"
              class="search-input"
              placeholder="Search Your Project"
              [(ngModel)]="searchValue"
              (keyup)="searchListings()"
            />
            <button class="search-button" (click)="searchListings()">
              SEARCH 
            </button>
          </div>
        </div>

      </div>
    </div>
    
    <div
      style="cursor: pointer;"
      class="card-container master12 col-md-12"
      *ngIf="filteredListings.length > 0"
    >
      <ng-container *ngFor="let card of filteredListings">
        <app-top-property-card
          [cardData]="card"
          [showEditDetailsButton]="true"
          [showViewDetailsButton]="true"
          [showInventoryButton]="true"
          [showdetails]="false"
          [showdetailsbutton]="false"
          [showdsize]="false"
          [showEditInventoryButton]="false"
        >
        </app-top-property-card>
      </ng-container>
    </div>

    <div *ngIf="filteredListings.length === 0">
      <app-not-found></app-not-found>
    </div>
    <br />

    <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
      ⬆
    </div>
  </div>
  <app-footer></app-footer>
</div>
