<!-- <mat-form-field class="search-form-field">
    <mat-icon matPrefix class="search-icon" style="margin-top: 5px;">search</mat-icon>
    <input matInput placeholder="Search Project Location or Builder" [(ngModel)]="searchTerm" style="margin-top: 8px;" (ngModelChange)="applySearchFilter()">
    <button mat-icon-button  matSuffix class="clear-icon" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>
   -->
  <div class="col-12 col-md-12 mb-2 mb-md-0">
    <div class="search-container">
      <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
      <input
        type="text"
        class="search-input"
        placeholder="Search Project Location or Builder"
        [(ngModel)]="searchTerm"
        (ngModelChange)="applySearchFilter()"
      />
      <button class="search-button" (click)="applySearchFilter()">
        SEARCH 
      </button>
    </div>
  </div>