import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '../_services/loading.service';
type Card = {
  imageUrl: string;
  title: string;
  subtitle: string;
  details: string;
  location: string;
  price: string;
  sourceName: string;
  pubDate: string;
  description: string;
  link:any;
};
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  @Input() cardData: any;
 
  cards: Card[] = [];

  card: { description: string | null } = { description: null };
  constructor(private http: HttpClient,
 private loadingService:LoadingService

  ) {}

  ngOnInit(): void {
   
    this.fetchData();
    this.card.description = 'Your description text goes here...';
  }



  truncateText(text: string, wordLimit: number = 300): string {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  }

  fetchData() {
    const url = 'https://newsdata.io/api/1/news?apikey=pub_515574f1ecfae471ae7d04b38a0dee48ca6df&q=property&country=in&language=en&category=top';
    this.loadingService.show()
    this.http.get(url).subscribe(
      (response: any) => {
        console.log("Response data:", response);
        this.loadingService.hide()
  
        // Assuming the data structure is { results: [...] }
        const results = response.results;
  
        // Extracting the desired fields from each result
        this.cards = results.map((item: any) => ({
          imageUrl: item.image_url,
          description: item.description,
          pubDate: item.pubDate,
          sourceName: item.source_name,
          link: item.link, // Ensure this field is populated
        }));
  
        console.log("Extracted Data:", this.cards);
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );
  }
  
  
}
