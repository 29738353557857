<div class="row" class="first" style="display: flex;">
  <div class="col-10" >
    <span style="align-items: center; font-weight: bold;" class="fontset">Contact Person Details</span>
  </div>
  <div class="col-2" >
    <span class="close-container">
      <button class="close-button"  (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </span>
  </div>
</div>
<div *ngIf="listingData" style="width: 100%; font-size: 18px;">
  <div class="row">
    <div class="col-md-7 mt-2">
      <h3 class="m-0 fontset">Posted By Owner</h3>
      <div style="display: flex; align-items: center;">
        <span class="labelall fontset">Name : </span>&nbsp;
        <span class="labelvalue">{{ listingData.fullName }}</span>
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
        <span  class="labelall fontset">Email : </span>&nbsp;
        <span class="labelvalue">{{ listingData.email }}</span>
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
        <span style="font-weight: 550; " class="labelall fontset">Mobile : </span>&nbsp;
        <span class="labelvalue">{{ listingData.mobileNumber }}</span>
      </div>      
    </div>
    <div class="col-md-5 mt-2">
    
      <div class="mt-1">
        <h3  class="m-0 fontset">Posted On {{formattedDate}}</h3>
      </div>
      <div style="display: flex; align-items: center;" class="mt-1">
        <div class="mt-1">
          <img alt="Profile Picture" class="profile-pic" src="../assets/images/profile.png">
        </div>
        
        <!-- <span style="font-weight: bold; " class="labelall">Price  : </span>&nbsp; -->
        <!-- <span class="labelvalue">{{listingData.asking | formatNumber }}</span> -->
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
        <!-- <span style="font-weight: bold; " class="labelall">Size : </span>&nbsp; -->
        <!-- <span class="labelvalue">  {{ listingData.feature.size }}{{listingData.feature.sizeUnit}}</span> -->
      </div>
      <div style="display: flex; align-items: center;" class="mt-2">
        <!-- <span style="font-weight: 550; " class="labelall">Type : </span>&nbsp; -->
        <!-- <span class="labelvalue">{{listingData.type}}</span> -->
      </div> 


      <!-- <div class="row col-sm-12" style="font-weight: bold;">
      {{ listingData.asking | formatNumber  }} | {{ listingData.feature.propertyOwnership }}
      </div>
      <div class="row col-sm-12 mt-2">
        {{ listingData.feature.size }}{{listingData.feature.sizeUnit}} &nbsp;| {{ listingData.noOfBadroom }} Bedrooms/{{listingData.type}}
      </div> -->

    </div>
  </div>
</div>

<div class="second mt-0">
  <div class="row col-12">
    <span style="margin-left: 5px ; " class="fontset">Please Provide Below Information</span>
  </div>
<!-- </div> -->

</div>

<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="row" style="margin-top: 5px;">
    <div class="col-md-7">
      <h3 class="fontset">Basic Information</h3>
      <div class="row col-sm-12" style="display: flex;">
        <h3 class="bold-black fontset">Your reason to buy is : </h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="reasonToBuy" aria-label="Select an option">
          <mat-radio-button value="Investment">Investment</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="Self Use">Self Use</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row col-sm-12" style="display: flex;">
        <h3 class="bold-black deepgap fontset">Are you a property dealer:</h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="propertyDealer" aria-label="Select an option">
          <mat-radio-button value="Yes">Yes</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <h3 class="bold-black deepgap fontset">By when you are planning to buy the property? : </h3>
      <p>
        <mat-radio-group formControlName="planningToBuy" aria-label="Select an option">
          <mat-radio-button value="3 months">3 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="6 months">6 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="more than 6 months">more than 6 months</mat-radio-button>
        </mat-radio-group>
      </p>
      
      <!-- <div class="component__inputGroup">
        <div>
          <h2>User Profile</h2>
          <p><strong>Full Name:</strong> {{ contactForm.get('name').value }}</p>
          <p><strong>Email:</strong> {{ contactForm.get('email').value }}</p>
          <p><strong>Mobile Number:</strong> {{ contactForm.get('mobile').value }}</p>
        </div>
      </div> -->
      
    </div>
    <div class="col-md-5">
      <h3 class="fontset">Optional Information</h3>
      <div class="row col-sm-12">
        
        <p>
          <mat-checkbox formControlName="homeLoan">I am interested in home loan</mat-checkbox><br>
          <mat-checkbox formControlName="siteVisits">I am interested in site visits.</mat-checkbox><br>
          <mat-checkbox formControlName="terms" >I accept Terms & Policy
          </mat-checkbox>
        </p>
      </div>
      <div class="button-container mt-2">
        <button class="button1" [disabled]="!contactForm.get('terms').value"
        [ngClass]="{'inactive-button': !contactForm.get('terms').value}">
        Submit
        </button>
      </div>
    </div>
  </div>
</form>
