import { Component, OnInit,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridOptions } from 'ag-grid-community';
import { HomeService } from '../_services/home.service';
import { ActionComponent } from '../Action/action.component';

@Component({
  selector: 'app-project-inventory',
  templateUrl: './project-inventory.component.html',
  styleUrls: ['./project-inventory.component.scss']
})
export class ProjectInventoryComponent implements OnInit {
  projectId: string | null = null;
  showTopIcon = false; 
  searchValue: string = '';
  IsLoading: boolean = false;
  topList: any[] = [];
  filteredListings: any[] = [];

  showButton: boolean;
  builder: boolean | string = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      console.log('Received ProjectId:', this.projectId);
      if (this.projectId) {
        localStorage.setItem('projectId', this.projectId);
      }
      this.projectId= localStorage.getItem("projectId")
      console.log('localprojectId',this.projectId);
      this.route.queryParams.subscribe(params => {
        this.showButton = params['showButton'] === 'false' ? false : true;
      });
      this.apiService();
    });
  }



  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToAddInventory() {
    let userinfo =localStorage.getItem("auth-token")
   if(userinfo){
    this.router.navigate(['/add-invetory'], {
            queryParams: { projectId: this.projectId }
          });
   }else{
    this.router.navigate(['/login'])
   }
  }

  stripHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }

  
  apiService() {
    this.IsLoading = true;
    this.homeService.getInventoryByProjectId(this.projectId).subscribe((res: any) => {
      this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList=res.payload
          this.filteredListings =this.topList;
          console.log("toplist",this.topList)
        } else {
          this.topList = [];  
          this.filteredListings = [];
        }
        
    },
    () => {
      this.IsLoading = false;
      this.topList = []; 
      this.filteredListings = [];
    });
  }

  // searchListings(): void {
  //   const searchTerm = this.searchValue.toLowerCase();
  //   this.filteredListings = this.topList.filter((listing) =>
  //     Object.values(listing).some((value) =>
  //       value && value.toString().toLowerCase().includes(searchTerm)
  //     )
  //   );
  // }
  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();
    this.filteredListings = this.topList.filter((listing) => {
      // Normalize title by removing extra spaces and converting to lowercase
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });
  }

  clearSearch(): void {
    this.searchValue = '';
    this.filteredListings = [...this.topList];
  }
  ngOnDestroy() {
  
  }

  
}
