import { Component, Input, OnInit } from '@angular/core';
import { SlideInterface } from './slide-interface';
import {
  interval,
  Observable,
  Subject,
  timer,
} from 'rxjs';
@Component({
  selector: 'app-image-slide',
  templateUrl: './image-slide.component.html',
  styleUrls: ['./image-slide.component.scss']
})
export class ImageSlideComponent implements OnInit {
  //  @Input() slides: string[] = [];
  // currentIndex: number = 0;
  // timeoutId?: number;
  @Input() slides: string[] = [];
  currentIndex: number = 0;
  intervalId: any;

//   ngOnInit(): void {
//     if (this.slides.length === 0) {
//       console.warn('No slides available.');
//     }
//     // this.resetTimer();
//   }

//   ngOnDestroy() {
//     window.clearTimeout(this.timeoutId);
//   }

//   // resetTimer() {
//   //   if (this.timeoutId) {
//   //     window.clearTimeout(this.timeoutId);
//   //   }
//   //   this.timeoutId = window.setTimeout(() => this.goToNext(), 4000);
//   // }

//   goToPrevious(): void {
//     const isFirstSlide = this.currentIndex === 0;
//     const newIndex = isFirstSlide
//       ? this.slides.length - 1
//       : this.currentIndex - 1;

//     // this.resetTimer();
//     this.currentIndex = newIndex;
//   }

//   goToNext(): void {
//     const isLastSlide = this.currentIndex === this.slides.length - 1;
//     const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

//     // this.resetTimer();
//     this.currentIndex = newIndex;
//   }

//   goToSlide(slideIndex: number): void {
//     // this.resetTimer();
//     this.currentIndex = slideIndex;
//   }

//   getCurrentSlideUrl() {
//     return `url('${this.slides[this.currentIndex]}')`;
//   }
// }

ngOnInit(): void {
  if (this.slides.length === 0) {
    console.warn('No slides available.');
  } else {
    this.startAutoSlide(); // Start the auto-slide functionality
  }
}

ngOnDestroy(): void {
  this.stopAutoSlide(); // Clean up interval on destroy
}

startAutoSlide(): void {
  this.intervalId = setInterval(() => {
    this.goToNext();
  }, 10000); // Change slide every 10 seconds
}

stopAutoSlide(): void {
  if (this.intervalId) {
    clearInterval(this.intervalId);
  }
}

goToNext(): void {
  const isLastSlide = this.currentIndex === this.slides.length - 1;
  this.currentIndex = isLastSlide ? 0 : this.currentIndex + 1;
}

goToSlide(slideIndex: number): void {
  this.currentIndex = slideIndex;
}

getCurrentSlideUrl(): string {
  return `url('${this.slides[this.currentIndex]}')`;
}
}