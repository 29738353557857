<div class="container-full d-flex justify-content-center align-items-center h-100">
    <div class="form-containerss">
        <div class="form-section sign-in-section">
            <a class="navbar-brand form mb-3" routerLink="/">
                <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
              </a>
            <form [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account-form">
                <span class="form-header">Please Enter Otp</span><br>
                <div id="otp" class="form-group text-center">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
                </div>
                <div class="position-relative">
                    <hr class="bg-200 mt-4 mb-4" />
                    <div class="divider-text-center">Set your password</div>
                </div>
                <div class="form-group">
                    <!-- <input
                      type="password"
                      placeholder="Enter New Password"
                      formControlName="newPassword"
                      class="form-control"
                    /> -->
                    <div class="password-container">
                        <input
                          [type]="showPassword ? 'text' : 'password'"
                          placeholder="Enter New Password"
                          formControlName="newPassword"
                          class="password-input form-control"
                        />
                        <img
                          [src]="showPassword ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
                          
                          alt="Toggle password visibility"
                          class="toggle-icon"
                          (click)="togglePasswordVisibility()"
                        />
                      </div>
              
                    <div class="error-messages" *ngIf="submitted && l.newPassword.errors">
                      <div *ngIf="l.newPassword.errors.required">New Password is required</div>
                      <div *ngIf="l.newPassword.errors.minlength">
                        Password must be at least 6 characters
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group">

                    <!-- <input type="password" placeholder="Enter Confirm Password" formControlName="confirmPassword" class="form-control" /> -->


                    <div class="password-container">
                        <input
                          [type]="showPasswords ? 'text' : 'password'"
                          placeholder="Enter Confirm Password"
                          formControlName="confirmPassword"
                          class="password-input form-control"
                        />
                        <img
                          [src]="showPasswords ? '../../assets/images/eye.png' : '../../assets/images/view (1).png'"
                          alt="Toggle password visibility"
                          class="toggle-icon"
                          (click)="togglePasswordVisibilitys()"
                        />
                      </div>



                    <div class="error-messages" *ngIf="submitted && l.confirmPassword.errors">
                        <div *ngIf="l.confirmPassword.hasError('required')">Confirm Password is required</div>
                        <div *ngIf="!l.confirmPassword.hasError('required') && l.confirmPassword.hasError('minlength')">
                          Password must be at least 6 characters
                        </div>
                        <div *ngIf="!l.confirmPassword.hasError('required') && !l.confirmPassword.hasError('minlength') && l.confirmPassword.hasError('mustMatch')">
                            Confirm Password Not Same
                        </div>
                      </div>
                </div>
                
                  
                <button class="update-password mb-2 btn btn-primary w-50 mx-auto">Update Password</button>
                <a routerLink="/login" class="login-link float-right text-center mt-3 d-block" style="color: #004aad;">Sign In</a>
            </form>
        </div>
    </div>
</div>
