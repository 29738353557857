import { Component, HostListener, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { Router } from '@angular/router';
import { LoadingService } from '../_services/loading.service';
import { HeaderSearchService } from '../_services/header-search.service';

@Component({
  selector: 'app-user-property',
  templateUrl: './user-property.component.html',
  styleUrls: ['./user-property.component.scss'],
})
export class UserPropertyComponent implements OnInit {
  showMore: boolean = false;
  listings: any[] = [];
  filteredListings: any[] = [];
  searchValue: string = '';
  Images: string[] = [];
  showTopIcon = false;

  IsLoading: boolean = false;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private loadingService: LoadingService,
    private searchService: HeaderSearchService
  ) {}

  ngOnInit(): void {
    this.getProprtybyUser();
  }

  getProprtybyUser() {
    this.homeService.getProprtybyUser().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.listings = response.payload;
          this.filteredListings = this.listings; // Initialize filtered list
          console.log('Listings:', this.listings);
        } else {
          console.error('Unexpected data format:', response);
          this.listings = [];
          this.filteredListings = [];
        }
      },
      (error) => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.listings = [];
        this.filteredListings = [];
      }
    );
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  // searchListings(): void {
  //   const searchTerm = this.searchValue.toLowerCase();
  //   this.filteredListings = this.listings.filter((listing) =>
  //     Object.values(listing).some((value) =>
  //       value && value.toString().toLowerCase().includes(searchTerm).valueOf()
  //     )
  //   );
  // }
  searchListings(): void {
    const searchTerm = this.searchValue.toLowerCase().trim();
    this.filteredListings = this.listings.filter((listing) => {
      // Normalize title by removing extra spaces and converting to lowercase
      const normalizedTitle = listing.title.replace(/\s+/g, ' ').toLowerCase().trim();
      return normalizedTitle.includes(searchTerm);
    });
  }
  
  clearSearch(): void {
    this.searchValue = '';
    this.filteredListings = [...this.listings];
  }
  
}
