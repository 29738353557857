import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { environmentbasic } from '../../environments/environment-basic';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  otp: string = '';
  useremail = '';
  resetform: FormGroup;
  submitted = false;
  showPassword: boolean = false;
  showPasswords: boolean = false;

  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    AOS.init();
    this.route.params.subscribe(val => {
      if (val && val.email) {
        this.useremail = val.email;
      } else {
        this.router.navigate(['/login']);
      }
    });

    // Initialize the form with a custom validator for matching passwords
    this.resetform = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
      },
      {
        validator: this.passwordMatchValidator // Attach the custom validator
      }
    );
  }

  // Custom validator to check if newPassword and confirmPassword match
  passwordMatchValidator(control: AbstractControl) {
    const password = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');

    if (password && confirmPassword && password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ ...(confirmPassword.errors || {}), mustMatch: true });
    } else if (confirmPassword.errors) {
        delete confirmPassword.errors.mustMatch;
        if (Object.keys(confirmPassword.errors).length === 0) {
            confirmPassword.setErrors(null);
        }
    }
    return null;
}


  // Convenience getter for easy access to form controls
  get l() {
    return this.resetform.controls;
  }

  onOtpChange(otp: string) {
    this.otp = otp;
  }

  onResetSubmit() {
    this.submitted = true;

    // If the form is invalid, stop execution
    if (this.resetform.invalid) {
      return;
    }

    // Construct the payload to send to the API
    const payload = {
      currentPassword: this.otp, // Use the otp as currentPassword
      newPassword: this.resetform.value.newPassword,
      confirmPassword: this.resetform.value.confirmPassword
    };

    this.authService.resetpasswords(this.useremail, payload).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          Swal.fire({
            icon: 'success',
            title: 'Password reset successfully.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          this.router.navigate(['/login']);
        } else if (response['message'] === "in.co.ksquaretech.backend.current.password.not.match") {
            Swal.fire({
              icon: 'error',
              title: 'Enter Valid OTP',
              showConfirmButton: false,
              timer: environmentbasic.timer
            });}
        
        else {
          Swal.fire({
            icon: 'error',
            title: response['message'],
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
        }
      }
    );
  }
  


 togglePasswordVisibility(): void {
   this.showPassword = !this.showPassword;
 }
 togglePasswordVisibilitys(){
    this.showPasswords = !this.showPasswords; 
 }
}
