<header class="header_ps paddings text-white" style="background-color: #004aad;">
	<div class="container-fluid">
	  <nav class="navbar navbar-expand-xl navbar-dark d-flex justify-content-between align-items-center">
		<!-- <button class="navbar-toggler" type="button" (click)="toggleDropdowns()">
		  <i class="fa fa-bars" aria-hidden="true"></i>
		</button> -->
		<!-- <span *ngIf="auths; else notLoggedIn"> -->
			<button mat-icon-button [matMenuTriggerFor]="menus"  class="navbar-toggler" *ngIf="useremail; else notLoggedIn">
				<i class="fa fa-bars" aria-hidden="true"></i>
			  </button>
			  <mat-menu #menus="matMenu">
			<button mat-menu-item routerLink="/add-project">
				<i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>&nbsp;New Project
			  </button>
			  <button mat-menu-item routerLink="/add-property">
				<i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>&nbsp;New Property
			  </button>
			  <button mat-menu-item routerLink="/contactUs">
				<i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>&nbsp;Any Query
			  </button>
			  </mat-menu>
			<!-- </span> -->
		<ng-template #notLoggedIn>
			<button mat-icon-button [matMenuTriggerFor]="menus" class="navbar-toggler">
				<i class="fa fa-bars" aria-hidden="true"></i>
			  </button>
			  <mat-menu #menus="matMenu">
				<button mat-menu-item (click)="navigateTologin()">
					<i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>&nbsp;New Project
				  </button>
				  <button mat-menu-item (click)="navigateTologin()">
					<i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>&nbsp;New Property
				  </button>
				  <button mat-menu-item routerLink="/contactUs">
					<i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>&nbsp;Any Query
				  </button>
				  </mat-menu>
		</ng-template>
		
		<a class="navbar-brand mx-auto d-lg-none" routerLink="/">
		  <img src="../../assets/images/log0783.png" alt="Logo" class="logo">
		</a>
		<a class="navbar-brand d-none d-lg-block" routerLink="/">
			<img src="../../assets/images/log0783.png" alt="Logo" class="logo">
		</a>
		<div class="navbar-content d-flex flex-wrap align-items-center ml-auto">
			<div class="notmobileq">
				<ul class="navbar-nav d-flex align-items-center">
					<!-- If the user is logged in -->
					<li class="nav-item" *ngIf="useremail; else notlogin">
					  <div class="d-flex align-items-center">
						<a routerLink="/add-project" *ngIf="builder" class="nav-link custom-button"
						  style="margin-right: 5px;">
						  <i class="fa fa-plus-circle" aria-hidden="true"></i>  New Project 
							<span
							style="color: white; margin-left: 5px;">|</span>
						</a>
						<a routerLink="/add-property" class="nav-link custom-button" style="margin-right: 5px;">
						  <i class="fas fa-building iconheader"></i> New Property<span
							style="color: white; margin-left: 5px;">|</span>
						</a>
						<a routerLink="/contactUs" class="nav-link custom-button" style="margin-right: 5px;">
						  <i class="fas fa-envelope iconheader"></i> Any Query?<span
							style="color: white; margin-left: 5px;">|</span>
						</a>
						<span class="username iconheader" style="margin-right: 5px;">
							{{userfullname}}&nbsp;
							 <span
							style="color: white;">|</span></span>
						<button mat-icon-button [matMenuTriggerFor]="menu" class="user-icon custom-button"
						  style="margin-right: 5px;">
						  <i class="fa fa-user-circle"></i>
						</button>
					  </div>
					  <mat-menu #menu="matMenu">
						<button mat-menu-item routerLink="/update/profile/username/userid/activetab">
						  <i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
						  <span>&nbsp; My Profile</span>
						</button>
						<button mat-menu-item (click)="navigateToUserProprty()">
						  <i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; My Property
						</button>
						<button mat-menu-item (click)="navigateToUserProjects()" *ngIf="builder">
						  <i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; My Projects
						</button>
						<button mat-menu-item (click)="userLogout()">
						  <i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; Logout
						</button>
					  </mat-menu>
					</li>
					<ng-template #notlogin>
					  <li class="nav-item">
						<div class="d-flex align-items-center">
							<a  (click)="navigateTologin()"  class="nav-link custom-button" style="margin-right: 20px;">
								<i class="fa fa-plus-circle" aria-hidden="true"></i>  New Project<span
								  style="color: white; margin-left: 20px;">|</span>
							  </a>
						  <a (click)="navigateTologin()" class="nav-link custom-button" style="margin-right: 20px;">
							<i class="fas fa-building"></i> New Property<span
							  style="color: white; margin-left: 20px;">|</span>
						  </a>
						  <a routerLink="/contactUs" class="nav-link custom-button" style="margin-right: 20px;">
							<i class="fas fa-envelope"></i> Any Query?<span
							  style="color: white; margin-left: 20px;">|</span>
						  </a>
						  <a routerLink="/login" class="nav-link custom-button" style="margin-right: 20px;">
							<i class="fas fa-sign-in-alt"></i> Login|Signup
						  </a>
						</div>
					  </li>
					</ng-template>
				  </ul>
			</div>
			
			<div class="destopnone">
				<ul class="navbar-nav d-flex align-items-center">
					<!-- If the user is logged in -->
					<li class="nav-item" *ngIf="useremail; else notlogina">
					  <div class="d-flex align-items-center">
						<button mat-icon-button [matMenuTriggerFor]="menua" class="user-icon custom-button"
						  style="margin-right: 5px;">
						  <i class="fa fa-user-circle"></i>
						</button>
					  </div>
					  <mat-menu #menua="matMenu">
						<button mat-menu-item routerLink="/update/profile/username/userid/activetab">
						  <i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
						  <span>&nbsp; My Profile</span>
						</button>
						<button mat-menu-item (click)="navigateToUserProprty()">
						  <i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; My Property
						</button>
						<button mat-menu-item (click)="navigateToUserProjects()" *ngIf="builder">
						  <i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; My Projects
						</button>
						<button mat-menu-item (click)="userLogout()">
						  <i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
						  &nbsp; Logout
						</button>
					  </mat-menu>
					</li>
					<ng-template #notlogina>
					  <li class="nav-item">
						<div class="d-flex align-items-center">
							<button mat-icon-button [matMenuTriggerFor]="menuas" class="user-icon custom-button"
							style="margin-right: 5px;">
							<i class="fa fa-user-circle"></i>
						  </button>
						  <mat-menu #menuas="matMenu">
							<button mat-menu-item routerLink="/login">
							  <i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
							  <span>&nbsp; Login | Signup</span>
							</button>
						</mat-menu>
						</div>
					  </li>
					</ng-template>
				  </ul>
			</div>
		
		</div>
	  </nav>
	</div>
  </header>
  