<app-header-property></app-header-property>
<app-menu></app-menu>

<div class="row p-2">
  <div class="col-md-3">
      <app-property-slide  (payloadEvent)="onPayloadReceived($event)"></app-property-slide>
  </div>
  <div class="col-md-9">
    <ng-container *ngIf="listings.length > 0; else noListings">
      <ng-container *ngFor="let listing of listings;">
        <!-- <app-property-card [listing]="listing"></app-property-card> -->
        <app-property-card [listing]="listing" [editButton]="false"></app-property-card>
      </ng-container>
      <div class="pagination-container">
        <span class="page-info">Page {{ currentPage + 1 }} of {{ totalPages }}</span>
      
        <div class="pagination">
          <button
            class="page-link"
            (click)="changePage(currentPage - 1)"
            [disabled]="currentPage === 0"
          >
            &lt; Previous Page
          </button>
      
          <ng-container *ngFor="let page of getPages(); let i = index">
            <button
              class="page-number"
              [class.active]="currentPage === i"
              (click)="changePage(i)"
            >
              {{ i + 1 }}
            </button>
          </ng-container>
      
          <button
            class="page-link"
            (click)="changePage(currentPage + 1)"
            [disabled]="currentPage + 1 >= totalPages"
          >
            Next Page &gt;
          </button>
        </div>
      </div>
      
      <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
        ⬆
      </div>
    </ng-container>
    <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template>
  
  </div>
</div>
<app-footer></app-footer>