import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareModule } from '@ngx-share/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmbedVideo } from 'ngx-embed-video';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import {MDBBootstrapModule } from 'angular-bootstrap-md';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import {NgxPaginationModule} from 'ngx-pagination';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { UpdateAssignmentComponent } from './update-assignments/update-assignments.component';
import { SearchAssignmentComponent } from './search-assignments/search-assignments.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchArtistComponent } from './search-artist/search-artist.component';

import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { AgGridModule } from 'ag-grid-angular';
import { ImageCropperModule } from 'ngx-image-cropper';  
import { SwiperModule } from 'swiper/angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ProfileUpdateComponent } from './profileupdate/profileupdate.component';


import { WorkGridButtonCellComponent } from './work-grid-btn-cell/work-grid-btn-cell.component';

import { NgxViewerModule } from 'ngx-viewer';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { QrCodeModule } from 'ng-qrcode';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MapComponent } from './map/map.component';
import { MatInputModule } from '@angular/material/input';

import { ArgmapComponent } from './argmap/argmap.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MatSelectModule} from '@angular/material/select';
import { EmailverifyComponent } from './verifymodel/emailverify/emailverify.component';
import { NgOtpInputModule } from 'ng-otp-input';

import { PropertyCardComponent } from './property-card/property-card.component';
import { AddPropertyComponent } from './add-property/add-property.component';
// import { OwlModule } from 'ngx-owl-carousel-o';
import {MatStepperModule} from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatSliderModule } from '@angular/material/slider';
import { PropertySlideComponent } from './property-slide/property-slide.component';
import { PropertyDetailsComponent } from './property-detail/property-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { PropertyOwnerComponent } from './property-owner/property-owner.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimeAgoPipe } from './time-ago.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { MapSelectorComponent } from './google-map/map-selector.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MaskMobileEmailPipe } from './mask-mobile-email.pipe';
import { ImageSlideComponent } from './image-slider/image-slide.component';
import { CroreFormatPipe } from './crore-format.pipe';
import { CapitalizePipe } from './pipe/capitalize.pipe';

import { MatTooltipModule } from '@angular/material/tooltip';
import { UserPropertyComponent } from './user-property/user-property.component';
import { TopPropertyCardComponent } from './top-property-card/top-property-card.component';
import { TopPropertyCardSmallComponent } from './top-property-card-small/top-property-card-small.component';
import { TopPropertyCardSemiSmallComponent } from './top-property-card-semi-small/top-property-card-semi-small.component';
import { HomePropertyComponent } from './home-property/home-property.component';
import { SafeHtmlPipe } from './pipe/SafeHtml.pipe';
import { StripHtmlPipe } from './pipe/StripHtml.Pipe ';
import { TopProjectComponent } from './top-project/top-project.component';
import { MyprojectsComponent } from './myprojects/myprojects.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { NewsComponent } from './news/news.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { ActionComponent } from './Action/action.component';
import { ProjectInventoryComponent } from './project-inventory/project-inventory.component';
import { TruncateWordsPipe } from './pipe/TruncateWords.Pipe';
import { TopProjectListingComponent } from './Projects-listing/Top-project/top-project-listing.component';
import { RecentlyAddedProjectComponent } from './Projects-listing/Recently-added-project/recently-added-project.component';
import { TrendingProjectListingComponent } from './Projects-listing/Trending-project/trending-project-listing.component';
import { PrivacyPolityComponent } from './footer-content/privacy-polity.component';
import { TermConditionComponent } from './footer-content/term-condition.component';
import { AboutUsComponent } from './footer-content/about-us.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { ContactUsComponent } from './footer-content/contact-us/contact-us.component';
import { ProjectCommonFilterComponent } from './project-common-filter/project-common-filter.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ContactSellerComponent } from './contact-seller/contact-seller.component';
import { FaqComponent } from './footer-content/FAQ/faq.component';
import { ContactemailComponent } from './contactemail/contactemail.component';
import { CapitalizeFirstLetterPipe } from './capitalizeFirstLetter.pipe';
import { ViewInventoryComponent } from './view-inventory/view-inventory.component';
import { HomeTopPropertyComponent } from './home-top-property/home-top-property.component';
import { NotFoundInvetoryComponent } from './not-found-invetory/not-found-invetory.component';
import { HeaderPropertyComponent } from './header-property/header-property.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
     SafeHtmlPipe,
    ForgotpasswordComponent,
    StripHtmlPipe,
    TruncateWordsPipe,
    ContractDetailComponent,
    UpdateAssignmentComponent,
    SearchAssignmentComponent,
    SearchArtistComponent,
    CapitalizeFirstLetterPipe,
    ResetpasswordComponent,
   
  
    
    ProfileUpdateComponent,
 
    WorkGridButtonCellComponent,
   
    ForgotpasswordComponent,
 
   
    MapComponent,
   
    ArgmapComponent,
    EmailverifyComponent,
    PropertyCardComponent,
    AddPropertyComponent,
    PropertySlideComponent,
    PropertyDetailsComponent,
    PropertyOwnerComponent,
    TimeAgoPipe,
    NotFoundComponent,
    MapSelectorComponent,
    MaskMobileEmailPipe,
    ImageSlideComponent,
    CroreFormatPipe,
    CapitalizePipe,
    UserPropertyComponent,
    TopPropertyCardComponent,
    TopPropertyCardSmallComponent,
    TopPropertyCardSemiSmallComponent,
    HomePropertyComponent,
    TopProjectComponent,
    MyprojectsComponent,
    AddProjectComponent,
    NewsComponent,
    AddInventoryComponent,
    ActionComponent,
    ProjectInventoryComponent,
    TopProjectListingComponent,
    RecentlyAddedProjectComponent,
    TrendingProjectListingComponent,
    PrivacyPolityComponent,
    TermConditionComponent,
    AboutUsComponent,
    ShareModalComponent,
    ContactUsComponent,
    ProjectCommonFilterComponent,
    ContactSellerComponent,
    FaqComponent,
    ContactemailComponent,
    ViewInventoryComponent,
    HomeTopPropertyComponent,
    NotFoundInvetoryComponent,
    HeaderPropertyComponent,
    LoginPopupComponent,
    
   
    

  ],
  imports: [
    MatTabsModule,
    MatSliderModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDividerModule,
    MatDialogModule ,
    MatStepperModule,   
    MatExpansionModule,
    MatChipsModule,
    GoogleMapsModule,
    MatTooltipModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDFaXNvUSNlqQoqlNBgCgppWcSeYxb5kDM'
    // }),

    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    Ng2SearchPipeModule,
    HttpClientModule,
    SocialLoginModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPaginationModule,
    // SlickCarouselModule,
    EmbedVideo.forRoot(),
    ImageCropperModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    PickerModule,
    NgbModule,
    EditorModule,
    MDBBootstrapModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    ShareModule,
    NgxViewerModule,
    MatAutocompleteModule,
    AngularMultiSelectModule,
    MatIconModule,
    MatMenuModule,
    QrCodeModule,
    MatProgressBarModule,
    MatCardModule,
    AgGridModule.withComponents([]),
    MatInputModule,
    LeafletModule,MatSelectModule,MatInputModule ,
    NgOtpInputModule,MatGridListModule,MatRadioModule,MatCheckboxModule,

    LeafletModule,MatSelectModule,MatInputModule,
    SwiperModule,
    HttpClientModule,InfiniteScrollModule
   
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1081048444803-etqr7jr3f25ct2ib0t751vj6desfegv6.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('247313394079751')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);