import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { EmbedVideoService } from 'ngx-embed-video';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../_services/profile.service';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoadingService } from '../_services/loading.service';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-property',
  templateUrl: './home-property.component.html',
  styleUrls: ['./home-property.component.scss'],
})
export class HomePropertyComponent implements OnInit {
  @ViewChild('searchkey') searchInput: ElementRef;
  private subscription: Subscription = new Subscription();

  public loading = true;
  listings: any[] = [];
  listingsData: any[] = [];
  page: number = 1;
  limit: number = 10;
  sortactivetab = 'trending';
  mediums: any = [];
  userinfo: any = [];
  userPortfolio: any = [];
  customizeBackgroundContents: any = [];
  categories: any = [];
  searchkey: any = [];
  checkedcat: any = [];
  checkedmedium: any = [];
  selectQueryType: any;
  count = 0;
  tableSize = 28;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsmedium: IDropdownSettings = {};
  selectedCategories = [] as any;
  selectedCategoryIds = [] as any;
  selectedMediums = [] as any;
  selectedMediumIds = [] as any;
  artParama: any = {
    artWorkCategories: [],
    limit: 28,
    mediumIds: [],
    offset: 0,
    searchString: '',
    sortCriteria: 'trending',
  };
  btnStyle = 'tablinks ';
  isLoggedIn = false;
  defaultThumbnail = '../assets/images/video.png';
  defaultThumbnailAudio = '../assets/images/audio.jpeg';
  maxCategoryMessage: boolean = false;
  searchResultMessage: boolean = false;
  maxMediumMessage: boolean = false;
  usertoken: any = [];
  searchValue: any = '';
  socialOrigin: any;
  useremail: any;
  payload: any;
  searchData: string = '';
  dropdownOpen = false;
  currentIndex: number = 0;
  queryDataValue:any;
  queryTypeValue:any;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    dots: false,
    infinite: true,
  };
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  selectOption(option: string) {
    console.log(`Selected option: ${option}`);
    this.dropdownOpen = false;
  }
  currentPage: number = 0; // Current page number (zero-based)
  pageSize: number = 10;   // Number of records per page
  totalPages: number = 0;  // Total number of pages
  totalElements: number = 0; // Total number of items

  showTopIcon = false; // Property to control icon visibility
  constructor(
    private homeService: HomeService,
    private router: Router,
    private searchService: HeaderSearchService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    console.log("HomePropertyComponent initialized");
    const queryData = localStorage.getItem("search_query");
    const queryType = localStorage.getItem('search_type');
    this.queryDataValue=queryData;
    this.queryTypeValue=queryType;
    this.selectQueryType = queryType;
    console.log(this.selectQueryType);
    this.onPayloadReceived(this.payload)
    this.searchService.searchData$.subscribe(data => {
      console.log('Data received from searchService:', data); // Debug log
      this.searchData = data;
      console.log('this.searchData',this.searchData);
      
      localStorage.setItem("search_query", data);
      if (data) {
        this.onPayloadReceived(this.payload)
        console.log('test1');
      } 
      else if(queryData)
      {
        this.onPayloadReceived(this.payload)
        console.log('test2');
      }
      else
      {
        this.onPayloadReceived(this.payload)
        console.log('test3');
      }
   
    });
  
  }



  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  
  onPayloadReceived(payload: any) {
    let   formattedPayload: any[] = [];
    if(payload){
      this.payload = payload;
    console.log(payload);
    this.loadingService.show();
     formattedPayload = this.formatFilters(
      this.payload?.filterName,
      this.payload?.filterValue
    );
    console.log(formattedPayload);
    const askingFilterIndex = formattedPayload.findIndex(
      (item) => item.filterName === 'asking'
    );

    if (askingFilterIndex !== -1) {
      const askingFilter = formattedPayload[askingFilterIndex];
      formattedPayload.splice(askingFilterIndex, 1);
      const [minValue, maxValue] = askingFilter.filterValue
        .split(' - ')
        .map((value) => value.trim());

      formattedPayload.push(
        {
          filterName: 'fromAsking',
          filterValue: minValue,
        },
        {
          filterName: 'toAsking',
          filterValue: maxValue,
        }
      );
    }
   

    }
    console.log('queryDataValue:', this.queryDataValue, typeof this.queryDataValue);
    if(this.queryDataValue){
      formattedPayload.push(
        {
          filterName: 'type',
          filterValue: this.queryTypeValue,
        }
      );
    }
    let filters: any = {
      filterConditions: formattedPayload,
      sortConditions: [],
    };
    filters['searchValue'] = this.searchData ? this.searchData : this.queryDataValue;

    if (this.searchData && this.searchData.trim() !== '') {
      filters['page'] = 0;
    } else {
      filters['page'] = this.currentPage;
    }
    filters['size'] = this.pageSize;
    console.log(filters);
    this.homeService.property(filters).subscribe(
      (response: any) => {
        if (response && response.payload && response.payload.items) {
          this.loadingService.hide();
          this.listings = response.payload.items;
          console.log('this.listings',this.listings);
          this.totalPages = response.payload.totalPages;  
          this.totalElements = response.payload.totalElements; 
          console.log('Listings:', this.listings);
          this.cdr.detectChanges();
        } else {
          console.error('Unexpected data format:', response);
          this.listings = [];
        }
      },
      (error) => {
        this.loadingService.hide();

        console.error('Error loading listings:', error);
        this.listings = [];
      }
    );
  }




  formatFilters(filterName, filterValue) {
    if (!Array.isArray(filterName)) {
      console.error('filterName is not an array or is undefined:', filterName);
      return [];
    }
  
    if (!Array.isArray(filterValue)) {
      console.error('filterValue is not an array or is undefined:', filterValue);
      return [];
    }
  
    const resultMap = {};
    filterValue.forEach((value, index) => {
      const name = filterName[index] || filterName[filterName.length - 1];
      if (!resultMap[name]) {
        resultMap[name] = value;
      } else {
        resultMap[name] += `, ${value}`;
      }
    });
  
    // Convert resultMap to resultArray
    const resultArray = Object.keys(resultMap).map((key) => ({
      filterName: key,
      filterValue: resultMap[key],
    }));
  
    console.log('Formatted Filters:', resultArray);
    return resultArray;
  }
  
  changePage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.onPayloadReceived(this.payload)
    }
  }
  getPages(): number[] {
    const pages: number[] = [];
    const start = Math.max(this.currentPage - 4, 0); // Show up to 4 pages before
    const end = Math.min(start + 9, this.totalPages - 1); // Show up to 9 pages total
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
