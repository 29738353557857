import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { finalize } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss']
})
export class AddInventoryComponent implements OnInit {

  @ViewChild(MatStepper) stepper: MatStepper;
  selectedFiles: File[] = [];
  selectedFile: File[] = [];
    amenitiesList: string[] = ['Gym', 'Swimming Pool', 'Parking', 'Garden', 'Clubhouse'];

  selectedUnit: number = 1;
  selectedWidth: string = '';
  selectedSize: string = '';

  calculatedWidth: string = '';
  calculatedSize: string = '';
  inputAsking: number = 0;
  inputWidth: string = '';
  inputSize: string = '';
  selectedFileNames: string[] = [];
  selectedFileName: string[] = [];

  isLinear = false;
  countryId = 259;
  stateList: any[] = [];
  cityDataList: any[] = [];
  id: any;
  stateId: number;
  stateName: string;
  data: any;
  cityid: number;
  payload: any;
  paramsData: any;
  projectid: string;
  statusValue: any;
  pdfurl: any;
  media: any = [];
  length: number = 1;
  image: any = [];
  Urls: string[] = [];
  projectId: string | null = null;
  // proId: string | null = null;

  imageItems: any[] = [
    {
      constrolName: 'bannerImage',
      purpose: 'bannerImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },

  ];

  imageItem: any[] = [
    {
      constrolName: 'imageUrls',
      purpose: 'propertiesImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },

  ];
  inventoryId: any
  uploadedImages: any[] = [];
  uploadedbanner: any[] = [];
  projectFeature: any
  Feature:any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  PlotFormGroup: FormGroup;
  FlatFormGroup: FormGroup;
  villasFormGroup: FormGroup;
  commercialsFormGroup: FormGroup;
  mansionsFormGroup: FormGroup;
  officesFormGroup: FormGroup;

  selectedValue: string = '';
  showPlotForm: boolean = false;
  showFlatForm: boolean = false;
  showVillasForm: boolean = false;
  showCommercialsForm: boolean = false;
  showMansionsForm: boolean = false;
  showOfficesForm: boolean = false;
  isFocused:boolean =false;
 
  propertyTypes: string[] = ['Office', 'Retail', 'Industrial', 'Other'];

  constructor(private _formBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) { }


  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Remove any non-digit characters
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10); // Limit to 10 digits
    }
  }
 
  ngOnInit(): void {

    
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      console.log('Received ProjectId:', this.projectId);
    });
    this.route.queryParamMap.subscribe(params => {
      this.inventoryId = params.get('inventoryId');
      if (this.inventoryId) {
        console.log('Received Inventory ID:', this.inventoryId);
        this.getInventoryById()
      }
    });

    this.firstFormGroup = this._formBuilder.group({
      description: ['',Validators.required],
      inventoryType: ['', Validators.required],
      // loanAvailable: ['', Validators.required],
      title: ['', Validators.required],
      isNegotiable: ['', Validators.required],
      totalSize: ['', Validators.required],
      // size: ['', Validators.required],
      sizeUnit: ['', Validators.required],
      aaskingPrice:['', Validators.required],
      aaskingPriceUnit:['', Validators.required],
      status: ['', Validators.required],
      UploadPlaneImages: []
    });

    this.PlotFormGroup = this._formBuilder.group({
      titleDeed: ['', Validators.required],
      encumbranceCertificate: ['', Validators.required],
      propertyTaxes: ['', Validators.required],
      location: ['', Validators.required],
      uniformityOfThePlot: ['', Validators.required],
      amenity: ['', Validators.required],
      completionCertificate: ['', Validators.required],
      soilQuality: ['', Validators.required],
      accessToThePlot: ['', Validators.required],
      avoidVerbalAgreement: ['', Validators.required],
      builder: ['', Validators.required],
      connectivity: ['', Validators.required],
      constructionApprovals: ['', Validators.required],
      flatPurchaseChecklist: ['', Validators.required],
      investmentPerspective: ['', Validators.required],
      naOrder: ['', Validators.required],
      powerOfAttorney: ['', Validators.required],
      security: ['', Validators.required],
      waterElectricitySupply: ['', Validators.required],
      zoningRisks: ['', Validators.required],
      approvals: ['', Validators.required],
      dueDiligence: ['', Validators.required],
      encumbrance: ['', Validators.required],
      historyOfTheProperty: ['', Validators.required]
    });
    this.FlatFormGroup = this._formBuilder.group({
      amenity: ['', Validators.required],
      reraCompliance: ['', Validators.required],
      // budget: ['', Validators.required],
      location: ['', Validators.required],
      occupancyCertificate: ['', Validators.required],
      areaOfFlat: ['', Validators.required],
      bankNoc: ['', Validators.required],
      constructionQuality: ['', Validators.required],
      reputedBuilders: ['', Validators.required],
      constructionApprovals: ['', Validators.required],
      legalCheckOfProperty: ['', Validators.required],
      titleVerification: ['', Validators.required],
      apartmentPossession: ['', Validators.required],
      verbalAgreement: ['', Validators.required],
      builderBuyerAgreement: ['', Validators.required],
      encumbrance: ['', Validators.required],
      landUsePermissions: ['', Validators.required],
      physicalVisit: ['', Validators.required],
      propertyLocation: ['', Validators.required],
      propertyTaxes: ['', Validators.required],
      resaleValue: ['', Validators.required],
      saleAgreement: ['', Validators.required],
      security: ['', Validators.required],
      serviceTax: ['', Validators.required],
    });
    this.villasFormGroup = this._formBuilder.group({
      amenity: ['', Validators.required],
      propertyTaxes: ['', Validators.required],
      constructionQuality: ['', Validators.required],
      occupancyCertificate: ['', Validators.required],
      security: ['', Validators.required],
      legalCompliance: ['', Validators.required],
      location: ['', Validators.required],
      locationConsiderations: ['', Validators.required],
      titleDeed: ['', Validators.required],
      accessibility: ['', Validators.required],
      // budget: ['', Validators.required],
      builderReputation: ['', Validators.required],
      constructionApprovals: ['', Validators.required],
      documentsForPropertyUse: ['', Validators.required],
      homeLoan: ['', Validators.required],
      investmentPerspective: ['', Validators.required],
      landDocuments: ['', Validators.required],
      modernFeatures: ['', Validators.required],
      noObjectionCertificates: ['', Validators.required],
      outdoorAmenities: ['', Validators.required],
      // pricing: ['', Validators.required],
      privacyAndSpace: ['', Validators.required],
      realEstateDeveloper: ['', Validators.required],
      sizeOfProperty: ['', Validators.required],
    });
    this.commercialsFormGroup = this._formBuilder.group({
      marketResearch: ['', Validators.required],
      location: ['', Validators.required],
      commercialPropertyTypes: ['', Validators.required],
      floorPlan: ['', Validators.required],
      leaseStructure: ['', Validators.required],
      rightBuilder: ['', Validators.required],
      riskAndReturn: ['', Validators.required],
      valueAssessment: ['', Validators.required],
      rentalIncome: ['', Validators.required],
      tenantProfiles: ['', Validators.required],
    });
    this.officesFormGroup = this._formBuilder.group({
      location: ['', Validators.required],
      amenity: ['', Validators.required],
      investmentGoals: ['', Validators.required],
      title: ['', Validators.required],
      cashFlow: ['', Validators.required],
      legalDocuments: ['', Validators.required],
      financialConsiderations: ['', Validators.required],
      infrastructure: ['', Validators.required],
      leaseStructure: ['', Validators.required],
      marketResearch: ['', Validators.required],
      taxPaymentStatus: ['', Validators.required],
      valueAssessment: ['', Validators.required],
      propertyDocumentation: ['', Validators.required],
      rightBuilder: ['', Validators.required],
      propertyLocation: ['', Validators.required],
    });
    this.mansionsFormGroup = this._formBuilder.group({
      location: ['', Validators.required],
      security: ['', Validators.required],
      amenity: ['', Validators.required],
      trustedDeveloper: ['', Validators.required],
      constructionApprovals: ['', Validators.required],
      documentsForPropertyUse: ['', Validators.required],
      highEndKitchens: ['', Validators.required],
      investmentPerspective: ['', Validators.required],
      smartHomes: ['', Validators.required],
    });
  }

  onInventoryTypeChange(event: MatSelectChange) {
    this.selectedValue = event.value;
    console.log(this.selectedValue);
    this.showPlotForm = this.selectedValue === 'plots';
    this.showFlatForm = this.selectedValue === 'flats';
    this.showVillasForm = this.selectedValue === 'villas';
    this.showCommercialsForm = this.selectedValue === 'commercials';
    this.showMansionsForm = this.selectedValue === 'mansions';
    this.showOfficesForm = this.selectedValue === 'offices';

  }

  getStepLabel(): string {
    switch (this.selectedValue) {
      case 'plots':
        return 'Plot Features';
      case 'flats':
        return 'Flat Features';
      case 'villas':
        return 'Villas Features';
      case 'commercials':
        return 'Commercial Features';
      case 'mansions':
        return 'Mansions Features';
      case 'offices':
        return 'Offices Features';
      default:
        return 'Features';
    }
  }
  onStepChange(event: any) {
    const stepIndex = event.selectedIndex;
    if (stepIndex === 0 && this.firstFormGroup.invalid) {
      event.preventDefault(); 
      this.firstFormGroup.markAllAsTouched();
      return;
    }
    if (stepIndex === 1 && this.selectedValue === 'Flat' && this.FlatFormGroup.invalid) {
      event.preventDefault();
      this.FlatFormGroup.markAllAsTouched();
      return;
    }

    if (stepIndex === 1 && this.selectedValue === 'Plot' && this.PlotFormGroup.invalid) {
      event.preventDefault();
      this.PlotFormGroup.markAllAsTouched();
      return;
    }
    if (stepIndex === 1 && this.selectedValue === 'villas' && this.villasFormGroup.invalid) {
      event.preventDefault();
      this.villasFormGroup.markAllAsTouched();
      return;
    }
    if (stepIndex === 1 && this.selectedValue === 'commercials' && this.commercialsFormGroup.invalid) {
      event.preventDefault();
      this.commercialsFormGroup.markAllAsTouched();
      return;
    }
    if (stepIndex === 1 && this.selectedValue === 'mansions' && this.mansionsFormGroup.invalid) {
      event.preventDefault();
      this.mansionsFormGroup.markAllAsTouched();
      return;
    }
    if (stepIndex === 1 && this.selectedValue === 'offices' && this.officesFormGroup.invalid) {
      event.preventDefault();
      this.officesFormGroup.markAllAsTouched();
      return;
    }

  }

  handleCancel() {
    // const id = this.route.snapshot.queryParamMap.get('id')
    // if (id) {
    //   this.router.navigate(['/UserProperty'])
    // }
    // else {
    //   this.router.navigate(['/home-property'])
    // }
    this.router.navigate(['/project-invetory'])
  }


  onSubmit() {
    const controls = this.firstFormGroup.controls;
    for (const i in controls) {
      if (controls.hasOwnProperty(i)) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    }
  
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      // payload.aaskingPrice = this.calculatePrice(payload.aaskingPrice, payload.aaskingPriceUnit);
      if (typeof payload.isNegotiable === 'boolean') {
        // Keep the boolean value as is
      } else {
        payload.isNegotiable = undefined;
      }
  
      const fieldsToRemove = ['UploadPlaneImages'];
      fieldsToRemove.forEach(field => {
        delete payload[field];
      });
  
      payload = {
        ...payload,
        imageUrls: this.uploadedImages
      };
  
      if (this.projectId) {
        this.saveInvetory(payload, this.projectId);
        this.stepper.next();
      }
  
      if (this.inventoryId) {
        this.UpdateBasicDetail(payload, this.inventoryId);
        this.onInventoryTypeChange({ value: payload.inventoryType } as MatSelectChange);
        this.stepper.next();
      }
    } else {
      console.log('Form is invalid');
    }
  }
  calculatePrice(price: string, unit: string): number {
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      return 0;
    }
    if (unit === 'Lac') {
      return numericPrice * 100000;
    } else if (unit === 'Cr') {
      return numericPrice * 10000000;
    }
    return numericPrice;
  }

  saveInvetory(payload: any, projectId: any) {
    this.homeService.saveInventory(payload, projectId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);

          this.inventoryId = response.payload.id;
          this.paramsData = response.payload;
          if (this.inventoryId) {
            this.router.navigate(['/add-invetory'], {
              queryParams: { inventoryId: this.inventoryId },
            });
          } else {
            console.error('No id returned in response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }


  UpdateBasicDetail(payload: any, id: any) {
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      payload = {
        ...payload,
        imageUrls: this.uploadedImages
      };
    }
    this.homeService.UpdateInventory(payload, id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.inventoryId = response.payload.id;
          this.paramsData = response.payload;
          if (this.inventoryId) {
            this.router.navigate(['/add-invetory'], {
              queryParams: { inventoryId: this.inventoryId },
            });
          } else {
            console.error('No id returned in response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }

  updateprojectfeatures() {

    let formValues: any;
    if (this.showPlotForm) {
      if (!this.PlotFormGroup.valid) {
        console.error('PlotFormGroup is invalid');
        return;
      }
      formValues = { ...this.PlotFormGroup.value };
    } else if (this.showFlatForm) {
      if (!this.FlatFormGroup.valid) {
        console.error('FlatFormGroup is invalid');
        return;
      }
      formValues = { ...this.FlatFormGroup.value };
    } else if (this.showVillasForm) {
      if (!this.villasFormGroup.valid) {
        console.error('VillasFormGroup is invalid');
        return;
      }
      formValues = { ...this.villasFormGroup.value };
    } else if (this.showCommercialsForm) {
      if (!this.commercialsFormGroup.valid) {
        console.error('CommercialsFormGroup is invalid');
        return;
      }
      formValues = { ...this.commercialsFormGroup.value };
    } else if (this.showMansionsForm) {
      if (!this.mansionsFormGroup.valid) {
        console.error('MansionsFormGroup is invalid');
        return;
      }
      formValues = { ...this.mansionsFormGroup.value };
    } else if (this.showOfficesForm) {
      if (!this.officesFormGroup.valid) {
        console.error('OfficesFormGroup is invalid');
        return;
      }
      formValues = { ...this.officesFormGroup.value };
    } else {
      console.error('No form is active');
      return;
    }
    const firstFormGroupValues = { ...this.firstFormGroup.value };
    // firstFormGroupValues.aaskingPrice = this.calculatePrice(firstFormGroupValues.aaskingPrice, firstFormGroupValues.aaskingPriceUnit);
    delete firstFormGroupValues.UploadPlaneImages;

    if (firstFormGroupValues.isNegotiable === 'Yes') {
      firstFormGroupValues.isNegotiable = true;
    } else if (firstFormGroupValues.isNegotiable === 'NO') {
      firstFormGroupValues.isNegotiable = false;
    }

    const projectFeature = {
      projectFeature: formValues
    };
    const payload = {
      ...firstFormGroupValues,
      ...projectFeature,
      imageUrls: this.uploadedImages
    };
    this.homeService.UpdateInventory(payload, this.inventoryId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          let proId = response.payload.project.id; 
          if (proId) {
            this.router.navigate(['/project-invetory'], {
              queryParams: { projectId: proId },
            });
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Inventory Added Successfully",
              showConfirmButton: false,
              timer: 1500
            });
            
          } else {
            console.error('No id returned in response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }

  getInventoryById() {
    this.homeService.getInventoryByInventoryId(this.inventoryId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response', response);
          this.data = response?.payload;
          this.uploadedImages = response?.payload?.inventoryImage?.map(media => media.url) || [];
           this.Feature=response?.payload?.projectFeature
           console.log(this.Feature);
           
           let askingValue =this.data.aaskingPrice
           if (this.data) {
            if (this.data.aaskingPriceUnit) {
              askingValue = this.data.aaskingPrice
            } else if (this.data.aaskingPriceUnit ) {
              askingValue = this.data.aaskingPrice ;
            }
          } else {
            console.warn('Feature data is not available');
          }
          this.firstFormGroup.patchValue({
            title: this.data.title,
            description: this.data.description,
            status: this.data.status,
            // loanAvailable: this.data.loanAvailable,
            inventoryType: this.data.inventoryType,
            isNegotiable: this.data.isNegotiable,
            sizeUnit: this.data.sizeUnit,
            totalSize: this.data.totalSize,
            aaskingPriceUnit:this.data.aaskingPriceUnit,
            aaskingPrice:askingValue
            // carpetSize: this.data.carpetSize,
          });
          this.officesFormGroup.patchValue({
            location: this.Feature.location || '',
            amenity: this.Feature.amenity || '',
            investmentGoals: this.Feature.investmentGoals || '',
            title: this.Feature.title || '',
            cashFlow: this.Feature.cashFlow || '',
            legalDocuments: this.Feature.legalDocuments || '',
            financialConsiderations: this.Feature.financialConsiderations || '',
            infrastructure: this.Feature.infrastructure || '',
            leaseStructure: this.Feature.leaseStructure || '',
            marketResearch: this.Feature.marketResearch || '',
            taxPaymentStatus: this.Feature.taxPaymentStatus || '',
            valueAssessment: this.Feature.valueAssessment || '',
            propertyDocumentation: this.Feature.propertyDocumentation || '',
            rightBuilder: this.Feature.rightBuilder || '',
            propertyLocation: this.Feature.propertyLocation || ''
          });
          this.PlotFormGroup.patchValue({
            titleDeed: this.Feature.titleDeed || '',
            encumbranceCertificate: this.Feature.encumbranceCertificate || '',
            propertyTaxes: this.Feature.propertyTaxes || '',
            location: this.Feature.location || '',
            uniformityOfThePlot: this.Feature.uniformityOfThePlot || '',
            amenity: this.Feature.amenity || '',
            completionCertificate: this.Feature.completionCertificate || '',
            soilQuality: this.Feature.soilQuality || '',
            accessToThePlot: this.Feature.accessToThePlot || '',
            avoidVerbalAgreement: this.Feature.avoidVerbalAgreement || '',
            builder: this.Feature.builder || '',
            connectivity: this.Feature.connectivity || '',
            constructionApprovals: this.Feature.constructionApprovals || '',
            flatPurchaseChecklist: this.Feature.flatPurchaseChecklist || '',
            investmentPerspective: this.Feature.investmentPerspective || '',
            naOrder: this.Feature.naOrder || '',
            powerOfAttorney: this.Feature.powerOfAttorney || '',
            security: this.Feature.security || '',
            waterElectricitySupply: this.Feature.waterElectricitySupply || '',
            zoningRisks: this.Feature.zoningRisks || '',
            approvals: this.Feature.approvals || '',
            dueDiligence: this.Feature.dueDiligence || '',
            encumbrance: this.Feature.encumbrance || '',
            historyOfTheProperty: this.Feature.historyOfTheProperty || ''
          });
          this.FlatFormGroup.patchValue({
            amenity: this.Feature.amenity || '',
            reraCompliance: this.Feature.reraCompliance || '',
            // budget: this.Feature.budget || '',
            location: this.Feature.location || '',
            occupancyCertificate: this.Feature.occupancyCertificate || '',
            areaOfFlat: this.Feature.areaOfFlat || '',
            bankNoc: this.Feature.bankNoc || '',
            constructionQuality: this.Feature.constructionQuality || '',
            reputedBuilders: this.Feature.reputedBuilders || '',
            constructionApprovals: this.Feature.constructionApprovals || '',
            legalCheckOfProperty: this.Feature.legalCheckOfProperty || '',
            titleVerification: this.Feature.titleVerification || '',
            apartmentPossession: this.Feature.apartmentPossession || '',
            verbalAgreement: this.Feature.verbalAgreement || '',
            builderBuyerAgreement: this.Feature.builderBuyerAgreement || '',
            encumbrance: this.Feature.encumbrance || '',
            landUsePermissions: this.Feature.landUsePermissions || '',
            physicalVisit: this.Feature.physicalVisit || '',
            propertyLocation: this.Feature.propertyLocation || '',
            propertyTaxes: this.Feature.propertyTaxes || '',
            resaleValue: this.Feature.resaleValue || '',
            saleAgreement: this.Feature.saleAgreement || '',
            security: this.Feature.security || '',
            serviceTax: this.Feature.serviceTax || ''        
          });
          this.villasFormGroup.patchValue({
            amenity: this.Feature.amenity || '',
            propertyTaxes: this.Feature.propertyTaxes || '',
            constructionQuality: this.Feature.constructionQuality || '',
            occupancyCertificate: this.Feature.occupancyCertificate || '',
            security: this.Feature.security || '',
            legalCompliance: this.Feature.legalCompliance || '',
            location: this.Feature.location || '',
            locationConsiderations: this.Feature.locationConsiderations || '',
            titleDeed: this.Feature.titleDeed || '',
            accessibility: this.Feature.accessibility || '',
            // budget: this.Feature.budget || '',
            builderReputation: this.Feature.builderReputation || '',
            constructionApprovals: this.Feature.constructionApprovals || '',
            documentsForPropertyUse: this.Feature.documentsForPropertyUse || '',
            homeLoan: this.Feature.homeLoan || '',
            investmentPerspective: this.Feature.investmentPerspective || '',
            landDocuments: this.Feature.landDocuments || '',
            modernFeatures: this.Feature.modernFeatures || '',
            noObjectionCertificates: this.Feature.noObjectionCertificates || '',
            outdoorAmenities: this.Feature.outdoorAmenities || '',
            // pricing: this.Feature.pricing || '',
            privacyAndSpace: this.Feature.privacyAndSpace || '',
            realEstateDeveloper: this.Feature.realEstateDeveloper || '',
            sizeOfProperty: this.Feature.sizeOfProperty || ''
          });
          this.commercialsFormGroup.patchValue({
            marketResearch: this.Feature.marketResearch || '',
            location: this.Feature.location || '',
            commercialPropertyTypes: this.Feature.commercialPropertyTypes || '',
            floorPlan: this.Feature.floorPlan || '',
            leaseStructure: this.Feature.leaseStructure || '',
            rightBuilder: this.Feature.rightBuilder || '',
            riskAndReturn: this.Feature.riskAndReturn || '',
            valueAssessment: this.Feature.valueAssessment || '',
            rentalIncome: this.Feature.rentalIncome || '',
            tenantProfiles: this.Feature.tenantProfiles || ''
          });
          this.mansionsFormGroup.patchValue({
            location: this.Feature.location || '',
            security: this.Feature.security || '',
            amenity: this.Feature.amenity || '',
            trustedDeveloper: this.Feature.trustedDeveloper || '',
            constructionApprovals: this.Feature.constructionApprovals || '',
            documentsForPropertyUse: this.Feature.documentsForPropertyUse || '',
            highEndKitchens: this.Feature.highEndKitchens || '',
            investmentPerspective: this.Feature.investmentPerspective || '',
            smartHomes: this.Feature.smartHomes || ''
          })

          this.displayUploadedImages(this.uploadedImages);
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  
  displayUploadedImages(images: string[]) {
    // Logic to display the images in the UI, e.g., as thumbnails or a gallery
  }
  

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  triggerFileInput1(): void {
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.click();
  }




  uploadFiles(): void {
    if (this.selectedFile.length) {
      for (let i = 0; i < this.selectedFile.length; i++) {
        const selectedFile = this.selectedFile[i];
        const type = this.imageItem[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;

        this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItem[i].loading = false; }))
          .subscribe((res: any) => {
            const media_type = (purpose === 'bannerImage') ? 'image' : ((purpose === 'shopVideo') ? 'video' : 'logo');
            this.uploadedbanner.push(res.url);
            this.cd.detectChanges();
          });
      }

      this.clearFileInput1();
    }
  }

  handleChanges(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFile.push(files[i]);
        this.selectedFileName.push(files[i].name);
        this.imageItem.push({ loading: true, purpose: 'bannerImage' });
      }
      this.cd.detectChanges();
    }
  }

  handleChange(event: any): void {
    const file = event.target.files;
    if (file && file.length) {
      for (let i = 0; i < file.length; i++) {
        this.selectedFiles.push(file[i]);
        this.selectedFileNames.push(file[i].name);
        this.imageItems.push({ loading: true, purpose: 'propertiesImage' });
      }
      this.cd.detectChanges();
    }
  }


  uploadFile(): void {
    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const selectedFile = this.selectedFiles[i];
        const type = this.imageItems[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;
        this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItems[i].loading = false; }))
          .subscribe((res: any) => {
            const media_type = (purpose === 'propertiesImage') ? 'image' : ((purpose === 'shopVideo') ? 'video' : 'logo');
            this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      }

      // Clear the file input and reset selected files and names
      this.clearFileInput();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
    this.cd.detectChanges();
  }
  clearFileInput1() {
    this.selectedFile = [];
    this.selectedFileName = [];
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.value = '';
    this.cd.detectChanges();
  }
  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }
  removeBanner(index: number): void {
    this.uploadedbanner.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }

}


