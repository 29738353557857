import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import { environmentbasic } from '../../environments/environment-basic';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  resetPassword:boolean=false;
  resetpasswordconfirms:boolean=false;
  resetform: FormGroup;
  id!: string;
  loading = false;
  submitted = false;
  otpactive = false;
  email: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      console.log('Email received in resetpassword component:', this.email);
    });

    this.resetform = this.formBuilder.group({
      resetpassword: ['', [Validators.required, Validators.minLength(6)]],
      resetpasswordconfirm: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: this.passwordMismatchValidator // Custom validator
    });
  }

  // Getter for easy access to form controls
  get l() { return this.resetform.controls; }

  // Custom validator to ensure new password is different from current password
  passwordMismatchValidator(formGroup: AbstractControl) {
    const currentPassword = formGroup.get('resetpassword')?.value;
    const newPassword = formGroup.get('resetpasswordconfirm')?.value;

    if (currentPassword && newPassword && currentPassword === newPassword) {
      return { passwordMismatch: true }; // Return error if passwords match
    }
    return null; // No error
  }

  onResetSubmit() {
    this.submitted = true;

    // Stop if the form is invalid
    if (this.resetform.invalid) {
      return;
    }

    this.authService.resetpassword(
      this.resetform.value.resetpassword,
      this.resetform.value.resetpasswordconfirm,
      this.email
    ).subscribe(response => {
      if (response['status'] === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: 'Password Reset Successfully.',
          showConfirmButton: true,
          // timer: environmentbasic.timer // Uncomment this if you want to auto-close the alert after a certain time
        }).then(() => {
          // this.router.navigate(['/']); 
          window.history.back();
        });
        
      } else {
        const messageMap = {
          'in.co.ksquaretech.backend.current.password.not.match': 'Current Password Does Not Match',
        };
        const userFriendlyMessage = messageMap[response['message']] || response['message'];
        Swal.fire({
          icon: 'error',
          title: userFriendlyMessage,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
      }
    });
  }
  toggleReset(): void {
    this.resetPassword = !this.resetPassword;
  }
  toggleResetconfirms(): void {
    this.resetpasswordconfirms = !this.resetpasswordconfirms;
  }
}
