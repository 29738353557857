<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <div class="master master12 py-1 px-xxl-4 px-3">
      <div class="page-heading d-flex justify-content-between align-items-center d-md-none">
        <h1 class="titles">My Inventory</h1>
        <button class="btn btn-primary ms-auto onlyhover " *ngIf="showButton"
          style=" font-family: 'Roboto', sans-serif;" (click)="navigateToAddInventory()">Add Inventory</button>
          
      </div>
      <div class="col-md-12 d-md-none mt-2">
        <!-- <mat-form-field class="w-100">
          <mat-icon matPrefix class="search-icon">search</mat-icon>
          <input matInput placeholder="Search Your Inventory" [(ngModel)]="searchValue" style="margin-top: 8px;"   (keyup)="searchListings()" />
          <button mat-icon-button matSuffix class="clear-icon" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field> -->

        <div class="search-container">
          <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
          <input
            type="text"
            class="search-input"
            placeholder="Search your Property"
            [(ngModel)]="searchValue"
            (keyup)="searchListings()"
          />
          <button class="search-button" (click)="searchListings()">
            SEARCH 
          </button>
        </div>
      </div>
      <div class="d-none d-md-flex col-md-12 justify-content-between align-items-center mt-4">
        <div class="col-md-3">
          <h1 class="titles">My Inventory</h1>
        </div>
        <div class="col-md-6">

          
          <!-- <mat-form-field class="w-100">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
            <input matInput placeholder="Search Your Inventory" [(ngModel)]="searchValue"   (keyup)="searchListings()" style="font-size: 16px; margin-top: 8px;" />
            <button mat-icon-button matSuffix class="clear-icon" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->

          <div class="search-container w-100 ">
            <mat-icon class="search-icon" style="color: gray;">search</mat-icon>
            <input
              type="text"
              class="search-input"
              placeholder="Search Your Inventory"
              [(ngModel)]="searchValue"
              (keyup)="searchListings()"
            />
            <button class="search-button" (click)="searchListings()">
              SEARCH 
            </button>
          </div>
        </div>
        <div class="col-md-3 text-end" style="text-align: end;">
          <button class="btn btn-primary px-4 py-2 onlyhover" *ngIf="showButton"
            style="font-family: 'Roboto', sans-serif; font-size: 14px;" (click)="navigateToAddInventory()">Add Inventory
          </button>
        </div>
      </div>
      <div style="cursor: pointer" class="card-container" *ngIf="filteredListings?.length > 0; else noListings">
        <ng-container *ngFor="let card of filteredListings">
          <app-top-property-card [cardData]="card" [showEditDetailsButton]="false" [showViewDetailsButton]="true"
            [showInventoryButton]="false" [showbugget]="false" [showdetailsbutton]="false"
            [showEditInventoryButton]="showButton !== false">
          </app-top-property-card>
        </ng-container>
      </div>

      <ng-template #noListings>
        <app-not-found-invetory></app-not-found-invetory>
    </ng-template>

      <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
        ⬆
      </div>

    </div>
  </div><br>
  <app-footer></app-footer>
</div>
