<div class="card" *ngIf="cardData.type !== 'viewAll';">
  <div class="row col-12" (click)="viewDetails($event)">

    <img *ngIf="cardData?.projectPlanImage?.length > 0; else checkBanner" [src]="cardData.projectPlanImage[0].url"
      class="Images">

    <ng-template #checkBanner>
      <img *ngIf="cardData?.inventoryImage?.length > 0; else fallbackImage" [src]="cardData.inventoryImage[0].url"
        class="Images">
    </ng-template>

    <ng-template #fallbackImage>
      <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg" class="Images">
    </ng-template>
  </div>



  <div class="row col-12" style="margin-top: 15px;">
    <div class="col-md-12 d-flex flex-column justify-content-start" (click)="viewDetails($event)">
      <span class="title">{{ cardData.title | capitalize }}</span>
    </div>
  </div>

  <div class="row col-12 mt-1">
    <!-- <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="text" [innerHTML]="(cardData.description | truncateWords: 12)"></span>
    </div> -->
    <div class="listing-description">
      <span class="description-text" [innerHTML]="cardData.description | safeHtml"></span>
    </div>
  </div>

  <!-- <div class="row col-12 mt-1">
    <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="title" *ngIf="showbugget">{{ cardData.fromPrice| formatNumber }} - {{cardData.toPrice | formatNumber}}</span>
    </div>
  </div> -->
  <div class="row col-12 mt-1">
    <div class="col-12 d-flex align-items-center justify-content-between text-nowrap" *ngIf="showbugget">
      <span class="title">₹{{ cardData.fromPrice }}&nbsp;{{cardData.fromPriceUnit | formatNumber}} - ₹{{cardData.toPrice }}&nbsp;{{cardData.toPriceUnit | formatNumber}}</span>
      <span><span class="title">Size:</span> {{ cardData.size }}&nbsp;{{ cardData.sizeUnit }}</span>
    </div>
  </div>
  <div class="row col-12">
    <div class="col-12 d-flex align-items-center justify-content-between text-nowrap" *ngIf="showdsize">
      <span class="title">₹{{ cardData.aaskingPrice }}&nbsp;{{ cardData.aaskingPriceUnit | formatNumber }}</span>
      <span><span class="title">Size:</span> {{ cardData.totalSize }}&nbsp;{{ cardData.sizeUnit }}</span>
    </div>

  </div>

  <div class="row col-12">
  <div class="col-md-12 mt-1" *ngIf="showbugget">
    <div>{{ cardData.details }}</div>
    <div class="target">{{ cardData.cityName }} &nbsp;{{ cardData.stateName }}</div>
  </div></div>
  <!-- <div class="row col-12 mt-1" *ngIf="showdetails">
    <div class="col-md-8 d-flex justify-content-between">
      <span ><span class="title3">Total Size:</span> {{ cardData.totalSize }}&nbsp;{{cardData.sizeUnit}}</span>
    </div>
    <div class="col-md-4 d-flex justify-content-between">
      <span ><span class="title3">Negotiable:</span> {{ cardData.isNegotiable }}</span>
    </div>
  </div> -->
  <!-- <div class="row col-12 mt-1">
    <div class="col-md-8 d-flex flex-column justify-content-start">
      <span ><span class="title3">Posted By Owner:</span>{{ cardData.createdAt | timeAgo }}</span>
    </div>
    <div *ngIf="showdetailsbutton" class="col-md-4 d-flex flex-column justify-content-start mb-2" (click)="viewDetails($event)">
        <button class="styled-button viewAll">View</button>
    </div>
  </div> -->
  <div class="row col-12 mt-1">
    <div class="col-md-8 d-flex flex-column justify-content-start mb-1 mb-md-0">
      <span><span class="title3">Posted By Owner : </span>{{ cardData.createdAt | timeAgo }}</span>
    </div>
    <div *ngIf="showdetailsbutton" class="col-md-4 d-flex flex-column justify-content-start d-flex" (click)="viewDetails($event)">
      <button class="styled-button viewAll" >View</button>
    </div>
  </div>
  <div class="col-md-12 button-container m-1">
    <button *ngIf="showEditDetailsButton" class="custom-btn mb-2 onlyhover" (click)="editDetails($event)">Edit
      Details</button>
    <button *ngIf="showViewDetailsButton" class="custom-btn mb-2 onlyhover" (click)="viewDetails($event)">View
      Details</button>
    <button *ngIf="showInventoryButton" class="custom-btn mb-2 onlyhover" (click)="inventory($event)">Inventory</button>
    <button *ngIf="showButton" class="custom-btn mb-2 onlyhover" (click)="editInventory($event)">Edit Inventory</button>
    <button *ngIf="showEditInventoryButton" class="custom-btn mb-2 onlyhover" (click)="editInventory($event)">Edit
      Inventory</button>
  </div>
</div>