<!-- <app-header></app-header><br> -->
<div class="wrapper">
  <app-header></app-header>
<div class="content"><br>
  <div *ngIf="listingData">
  <div class="col-12 first-container ">
    <div class="listing-image">
      <ng-container *ngIf="Images.length > 0; else noImage">
        <app-image-slide [slides]="Images"></app-image-slide>
      </ng-container>
      <ng-template #noImage>
        <app-image-slide [slides]="['https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg']"></app-image-slide>
      </ng-template>
    </div>
    </div>

    <div class="col-12 first-container">
      <mat-card class="project-overview " style="text-align: center;">
        <mat-card-title class="project-title mb-3">
          {{ listingData.title  | capitalizeFirstLetter}}<br>
        </mat-card-title>
  
        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <i class="fas fa-map-marker-alt"></i>
              <span>Area:</span>
              <strong>{{listingData.area | capitalizeFirstLetter}}</strong>
            </div>
            <div class="detail">
              <i class="fas fa-bed"></i> 
               <span>Bedrooms:</span>
               <strong>  {{ listingData.noOfBadroom || 0 }} Bedrooms, </strong>
             </div>
             <div class="detail">
              <i class="fas fa-door-open"></i>
               <span>Bathrooms:</span>
               <strong>  {{ listingData.feature?.noOfBathroom || 0 }} Bathrooms, </strong>
             </div>
             <div class="detail">
              <i class="fas fa-window-maximize"></i>

               <span>Balconies:</span>
               <strong>  
       
                {{ listingData.feature?.noOfBalconies || 0 }} Balconies</strong>
             </div>
             <div class="detail">
              <i class="fas fa-ruler-combined"></i>
               <span>Size:</span>
               <strong>{{listingData?.feature?.size}} {{listingData?.feature?.sizeUnit}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-money-bill-wave"></i>
               <span>Price</span>
               <strong> {{ listingData.asking}}&nbsp;{{listingData?.feature?.askingUnit | formatNumber}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-folder"></i>
               <span>Property Type</span>
               <strong>{{ listingData.type }}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-money-check-alt"></i> 
               <span>Price per {{listingData?.feature?.sizeUnit}}:</span>
               <strong>₹ {{total / listingData?.feature?.size| number:'1.0-0'}}</strong> 
             </div>
             <div class="detail">
              <i class="fas fa-square"></i>
               <span>Address:</span>
               <strong>{{ listingData.address | capitalizeFirstLetter}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-building"></i> 
               <span>Total Floors:</span>
               <strong>{{listingData?.feature?.totalFloors || 0}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-calendar-alt"></i>
               <span>Property Age:</span>
               <strong>{{listingData?.feature?.propertyAge}} </strong>
             </div>
             <div class="detail">
              <i class="fas" [ngClass]="listingData?.status ? 'fa-check-circle' : 'fa-times-circle'"></i>
               <span>Status</span>
               <strong> {{ listingData?.status ? 'Active' : 'Inactive' }}</strong>
             </div>
             
         
          </div>
          <hr>
          <div class="detail1">
            <span>Description</span>
            <div class="description">{{listingData.description}}</div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-12 first-container">
      <mat-card class="project-overview " style="text-align: center;">
        <mat-card-title class="project-title mb-3">
          Project Specifications
          <br>
        </mat-card-title><hr>
        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <i class="fas fa-couch"></i>
              <span>Furnishing:</span>
              <strong>{{listingData?.feature?.furnishing}}</strong>
            </div>
            <div class="detail">
              <i class="fas fa-compass"></i> 
               <span>Direction:</span>
               <strong>{{listingData?.feature?.direction}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-map-marker-alt"></i>
               <span>Nearby Places:</span>
               <strong>{{listingData?.feature?.placesNearby}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-money-bill-wave"></i>
               <span>Power Backup</span>
               <strong> {{listingData?.feature?.powerBackup}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-barcode"></i> 
               <span>Property Code</span>
               <strong>{{listingData?.propertyCode}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-key"></i>
               <span>Property Ownership:</span>
               <strong> {{listingData?.feature?.propertyOwnership}}</strong> 
             </div>
             <div class="detail">
              <i class="fas fa-exchange-alt"></i>
               <span>Transaction Type:</span>
               <strong> {{listingData?.feature?.transactionType}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-binoculars"></i>
               <span>Over Looking:</span>
               <strong>{{listingData?.feature?.overLooking}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-hard-hat"></i>
               <span>Construction:</span>
               <strong>{{listingData?.feature?.construction}} </strong>
             </div>
             <div class="detail">
              <i class="fas fa-globe"></i>
               <span>Buildup Area</span>
               <strong> {{listingData?.feature?.buildupArea | capitalizeFirstLetter}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-handshake"></i>
               <span>Negotiable:</span>
               <strong>{{listingData?.feature?.negotiable}}</strong>
             </div>
             <div class="detail">
              <i class="fas fa-dollar-sign"></i> 
               <span>Loan Available</span>
               <strong> {{listingData?.feature?.loanAvailable}}</strong>
             </div>
             
         
          </div>
          <hr>
          <div class="row justify-content-center">
            <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div><h2 class="OwnerDetails">Owner Details</h2></div> 
                <i class="fa fa-user-circle" style="color: #004aad; font-size: 100px;"></i>
            </div>
            <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div *ngIf="showowner">
                    <p class="Descriptionfont"><i class="fas fa-user"></i> {{ listingData?.user?.fullName }}</p>
                    <p class="Descriptionfont"><i class="fas fa-map-marker-alt"></i> {{ listingData?.cityName }}</p>
                </div>
                <button class=" custom-button" (click)="openContactDetailsDialog(listingData)">Contact Details</button>
                <!-- <button class=" custom-button" (click)="loginPopup()">Contact Details</button> -->

            </div>
        </div>
        </mat-card-content>
      </mat-card>
    </div>




</div>
<div class="col-12 first-container">
  <mat-card class="project-overview">
    <mat-card-content>
      <div class="project-details">
        <div class="detail mt-2">
          <span class="posted" style="color: black;">Posted On Owner :</span>
          <strong style="color: gray;">{{postdate | timeAgo }}</strong>
        </div>
        <div class="detail10 mt-2 button-container">
          <button mat-button class="right-align-button" (click)="back()">Back</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

</div>
<br>
<app-footer></app-footer>
</div>

