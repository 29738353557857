// import { Pipe, PipeTransform } from '@angular/core';
// @Pipe({
//   name: 'formatNumber'
// })
// export class CroreFormatPipe implements PipeTransform {
//   transform(value: number | null, format: string = 'crore'): string {
//     if (value === null || value === undefined) {
//       return 'N/A';
//     }
//     let formattedValue: string;
//     switch (format) {
//       case 'lac':
//         formattedValue = this.formatLac(value);
//         break;
//       case 'crore':
//       default:
//         formattedValue = this.formatCrore(value);
//         break;
//     }
//     return formattedValue;
//   }
//   private formatLac(value: number): string {
//     if (value < 100000) {
//       return `₹${value}`;
//     } else {
//       const lacValue = value / 100000;
//       return lacValue % 1 === 0
//         ? `₹${Math.floor(lacValue)} Lac`
//         : `₹${parseFloat(lacValue.toFixed(2)).toString()} Lac`;
//     }
//   }
//   private formatCrore(value: number): string {
//     if (value < 10000000) {
//       const lakhValue = value / 100000;
//       return lakhValue % 1 === 0
//         ? `₹${Math.floor(lakhValue)} Lac`
//         : `₹${parseFloat(lakhValue.toFixed(2)).toString()} Lac`;
//     } else {
//       const croreValue = value / 10000000;
//       return croreValue % 1 === 0
//         ? `₹${Math.floor(croreValue)} Crore`
//         : `₹${parseFloat(croreValue.toFixed(2)).toString()} Crore`;
//     }
//   }
// }
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class CroreFormatPipe implements PipeTransform {

  transform(value: string | null): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Use a regular expression to find 'Cr' followed by nothing or spaces.
    const regex = /Cr\b/;

    // If 'Cr' exists at the end of the string or is isolated (not followed by other letters or numbers)
    if (regex.test(value)) {
      return value.replace('Cr', 'Crore');
    }

    // Return value as is if 'Cr' isn't found correctly
    return value;
  }
}
